import React, { useState } from "react";
import style from "./table.module.scss";
import { testimonialsApi } from "../../../api/testimonials";
import { useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { R } from "../../../constants/resources";

export default function Table({ value, setValues, totalCount, setTotalCount}) {

  const navigate = useNavigate();

  const getStatusLabel = (status) => {
    switch (status) {
      case "1":
        return "Published";
      case "0":
        return "Archived";
      case "2":
        return "Draft";
      default:
        return "Unknown";
    }
  };


  const getDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const dealer_id = localStorage.getItem('dealerId');

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState();
  const [hasNextPage, sethasNextpage] = useState();

  const [pagination, setPagination] = useState({
    pageSize : 0,
    pageIndex : 1
  })


  useEffect(() => {
    const res = async () => {
      try {
        const res = await testimonialsApi.getTestimonials(dealer_id, pagination.pageIndex);
        setValues(res.data);
        setPageCount(res.totalPageCount);
        setCurrentPage(res.currentPage);
        sethasNextpage(res.hasNexPage);
        setTotalCount(res.totalCount);
      } catch (error) {
        console.log(error);
      }
    }
    res();
  }, [dealer_id, pagination.pageIndex, setTotalCount])

  const handleNextPage = () => {
    if (hasNextPage) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    }
  };
  
  const handlePreviousPage = () => {
    if (pagination.pageIndex > 0) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    }
  };
  


  const onDelete = async (id) => {
    try {
      const res = await testimonialsApi.dropTestimonials(id);
      console.log('your deleted data is -->', res);
      const updatedRes = await testimonialsApi.getTestimonials(dealer_id);
      setValues(updatedRes);
    } catch (error) {
      console.log(error);
    }
  };


  const columns = React.useMemo(
    () => [
      {
        accessorKey: "sno",
        header: "S.No",
        cell: (info) => info.row.index + 1,
      },

      // {
      //   accessorKey: "descriptions",
      //   header: "Descriptions",
      //   cell: (info) => {
      //     const description = info.getValue();
      //     return description.length > 20
      //       ? `${description.slice(0, 20)}...`
      //       : description;
      //   },
      // },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
      },

      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => getStatusLabel(row.original.status),
      },
      {
        accessorKey: "create_at",
        header: "Created Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "update_at",
        header: "Updated Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "action",
        header: "Actions",
        cell: ({ row }) => <ActionButtons data={row.original} />,
      },
    ],
    []
  );



  const table = useReactTable({
    data: value,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const ActionButtons = ({ data }) => {

    return (
      <div className={style["action-container"]}>
        <img
          src={R.ic_edit_pen}
          alt="edit"
          onClick={() => onEditClick(data.id)}
          className={style["action-icon"]}
        />
        <img
          src={R.ic_delete}
          alt="delete"
          onClick={() => onDelete(data.id)}
          className={style["action-icon"]}
        />
      </div>
    );
  };

  const onEditClick = (id) => {
    console.log('edited_id -->', id);

    navigate("/create-testinomial", { state: { id: id } });
  };

  return (
    <>
      <div className={`${style["blog-table-container"]} table-responsive`}>
        <table
          className={`${style["table-wrapper"]} table table-bordered table-hover`}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={style["table-header-wrapper"]}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={style["table-header"]}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className={style["table-row"]}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={style["pagination-action-wrapper"]}>
        <button onClick={handlePreviousPage} disabled={currentPage == 1}>
          Back
        </button>
        <p>
          Page {currentPage} of {pageCount}
        </p>

        <button onClick={handleNextPage} disabled={!hasNextPage}>
          Next
        </button>
      </div>
    </>
  );
}
