import React, { useState } from "react";
import style from "./blogTable.module.scss";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { R } from "../../../constants/resources";
import { blogs } from "../../../api/blogs";
import { HiOutlineExternalLink } from "react-icons/hi";

export default function BlogTable({
  data = [],
  fetchBlogs,
}) {
  const navigate = useNavigate();

  const getStatusLabel = (status) => {
    switch (status) {
      case "1":
        return "Published";
      case "0":
        return "Archived";
      case "2":
        return "Draft";
      default:
        return "Unknown";
    }
  };

  const getDate = (date) => {
    return new Date(date).toLocaleDateString();
  };


  const onDelete = async (id) => {
    try {
      await blogs.setStatus({ status: 0, blog_id: id });
      fetchBlogs();
    } catch (error) {
      console.log("Error during status update:", error);
    }
  };

  const onToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === "1" ? "2" : "1";
    const dateClicked = new Date().toISOString().slice(0, 19).replace("T", " ");

    try {
      await blogs.setStatus({
        status: newStatus,
        blog_id: id,
        published_date: newStatus === "1" ? dateClicked : null,
      });

      // Log the data along with the recorded date
      console.log({
        blog_id: id,
        previousStatus: currentStatus,
        newStatus: newStatus,
        dateClicked: dateClicked,
      });

      fetchBlogs(); // Optionally refresh the data or trigger a re-render
    } catch (error) {
      console.log("Error during status update:", error);
    }
  };

  const columns = React.useMemo(
    () => [
      { accessorKey: "title", header: "Title" },
      { accessorKey: "author", header: "Author" },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => getStatusLabel(row.original.status),
      },
      {
        accessorKey: "create_at",
        header: "Created Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "published_date",
        header: "Published Date",
        cell: (info) => {
          const value = info.row.original.published_date;
          return value ? getDate(value) : "-";
        },
      },
      {
        accessorKey: "action",
        header: "Actions",
        cell: ({ row }) => <ActionButtons data={row.original} />,
      },
    ],
    []
  );

  const handleLinkClick = () => {
    window.open("https://maxmotors.motorpedia.site/bloggrid8", "_blank");
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const ActionButtons = ({ data }) => {
    const isPublished = data.status === "1";

    return (
      <div className={style["action-container"]}>
        <img
          src={R.ic_edit_pen}
          alt="edit"
          onClick={() => onEditClick(data)}
          className={style["action-icon"]}
          style={{ cursor: isPublished ? "not-allowed" : "pointer" }}
        />
        <img
          src={R.ic_delete}
          alt="delete"
          onClick={() => !isPublished && onDelete(data.id)}
          className={style["action-icon"]}
          style={{ cursor: isPublished ? "not-allowed" : "pointer" }}
        />

        <button
          onClick={async () => {
            await onToggleStatus(data.id, data.status);
          }}
          className={`${style["toggle-button"]} ${
            isPublished ? style["greyish-text"] : ""
          }`}
        >
          {isPublished ? "Unpublish" : "Publish"}
        </button>
        {isPublished && (
          <HiOutlineExternalLink
            onClick={handleLinkClick}
            className={style["action-icon"]}
            style={{
              cursor: isPublished ? "pointer" : "not-allowed",
              fontSize: "24px",
            }}
          />
        )}
      </div>
    );
  };

  const onEditClick = (data) => {
    navigate("/create-blogs", { state: data });
  };

  return (
    <div className={`${style["blog-table-container"]} table-responsive`}>
      <table
        className={`${style["table-wrapper"]} table table-bordered table-hover`}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={style["table-header-wrapper"]}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className={style["table-header"]}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={style["table-row"]}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
