import React, { useEffect, useState } from "react";
import style from "./editCarDetails.module.scss";
import ImageCard from "../add-new-car/components/image-card/ImageCard";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import SelectableButton from "../../components/buttons/SelectableButton";
import InputField from "../../components/input-field/InputField";
import BackButton from "../../components/buttons/BackButton";
import { postData } from "../../components/services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { publicApi } from "../../api/publicApi";
import { editCarrDetails } from "../../store/car/carAction";
import { carApi } from "../../api/carApi";
import MultiSelect from "../../components/buttons/multiSelect";
import DateField from "../../components/input-field/dateField";
import moment from "moment";
import { toast } from "react-toastify";
import SecondaryHeading from "../../components/primary-heading/SecondaryHeading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ErrorMessage from "../../components/input-field/ErrorMessage.jsx";
import { formatIndianCurrency } from "../../utils/PrivateRoutes.js";
import { geographicRegion } from "../../api/geoRegion.js";

export default function EditCarDetails() {

  const [formData, setFormData] = useState({
    is_inventory: "update",
    carSkuId: "",
    regNumber: "",
    makeId: null,
    fuelType: "",
    status: "",
    transmissionType: null,
    images: null,
    makeYear: null,
    regYear: null,
    mspPrice: "",
    ownerType: null,
    kmDriven: null,
    discounted_price: "",
    listingPrice: "",
    discountPercent: "",
    modelId: "",
    variantId: "",
    insuranceType: "",
    insur_validity_dt: null,
    insuranceExpMonth: "",
    insuranceExpYear: "",
    isCngFitted: 0,
    isClassified: 0,
    isAssured: 0,
    carDescription: "",
    metaTag: "",
    carStatus: "",
    msp_price: "",
    colour: "",
    trending: 0,
    discounted: 0,
    newModel: 0,
    newStock: 0,
    state: "",
    regCityId: "",
  });

  const [loaderTrue, setloaderTrue] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);
  const [coloList, setColorList] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [alreadyImg, setalreadyImg] = useState([]);
  const [flags, setFlags] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageTypeEnum, setimageTypeEnum] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [requiredError, setRequiredError] = useState("");
  const [geoRegion, setGeoRegion] = useState({ state: [], city: [] });

  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
      const res1 = await carApi.imgTypeEnumList();
      setimageTypeEnum(res1);
    } catch (err) {
      console.log(err);
    }
  };

  const getCarDetailById = async (data) => {
    const requestBody = {
      sku_id: [state.data.carSkuId],
    };

    try {
      setloaderTrue(true);
      const res = await carApi.getCarList(requestBody);
      const data = res.data[0];

      setFormData({
        carSkuId: data?.carSkuId || "",
        regNumber: data?.reg_no || "",
        listingPrice: data?.carPrice || "",
        makeId: data?.makeId || null,
        fuelType: data?.fuelType || "",
        status: data?.status || "",
        transmissionType: data?.transmissionid || null,
        makeYear: data?.makeYear || null,
        regYear: data?.reg_year || null,
        mspPrice: data?.msp_price || "",
        ownerType: data?.ownerType || null,
        kmDriven: data?.drivenKms || null,
        discounted_price: state?.data?.discounted_price || "",
        discountPercent: data?.discountPercent || 0,
        modelId: data?.modelId || "",
        variantId: data?.varientId || "",
        insuranceExpMonth:
          data?.insurance_type_id !== 5 ? data?.insurance_exp_month : "",
        insuranceExpYear:
          data?.insurance_type_id !== 5 ? data?.insurance_exp_year : "",
        insuranceType: data?.insurance_type_id || null,
        carDescription: data?.car_description || "",
        metaTag: data?.metaTag || "",
        carStatus: data?.car_status || "",
        isAssured: data?.isAssured || 0,
        isClassified: +data?.is_classified || 0,
        isCngFitted: +data?.is_cng_fitted || 0,
        trending: data?.trending || 0,
        discounted: data?.discounted || 0,
        newModel: data?.new_model || 0,
        newStock: data?.newStock || 0,
        state: data?.stateId || "",
        regCityId: data?.cityId || "",
        colour: data?.colour || "",
      });
      setloaderTrue(false);
      setalreadyImg(res["data"] || []);
    } catch (err) {
      console.log(err);
      setloaderTrue(false);
    }
  };

  const fetchAndSetGeoRegions = async () => {
    try {
      const stateData = await geographicRegion.getStateNames();
      setGeoRegion((prev) => ({ ...prev, state: stateData?.data }));
      const stateId = formData?.state;
      if (stateId) {
        const cityData = await geographicRegion.getCityNames({
          state: stateId,
        });
        setGeoRegion((prev) => ({ ...prev, city: cityData?.data }));
      } else {
        setGeoRegion((prev) => ({ ...prev, city: [] }));
        setFormData((prev) => ({ ...prev, city: "", state: "" }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    colors();
  }, []);

  useEffect(() => {
    fetchAndSetGeoRegions();
  }, [formData.state]);

  const colors = async () => {
    try {
      const res = await publicApi.getColors();
      setColorList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (formData?.makeId && formData.makeYear) {
      models();
    }
  }, [formData?.makeId, formData.makeYear]);

  useEffect(() => {
    if (formData?.modelId) varients();
  }, [formData?.modelId]);

  const models = async () => {
    try {
      const res = await publicApi.modelList({
        make: formData?.makeId,
        year: formData?.makeYear,
      });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      const res = await publicApi.varientList({ model: formData?.modelId });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    brands();
    getCarDetailById(state?.data?.car_id);
  }, [state?.data?.car_id]);

  // useEffect(() => {
  //   if (formData?.makeId && formData?.model_year) {
  //     models();
  //   }
  // }, [formData?.makeId, formData?.model_year]);

  // useEffect(() => {
  //   if (formData?.modelId) varients();
  // }, [formData?.modelId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "listingPrice" ? value.toString().replace(/,/g, "") : value,
    });
  };

  const dispatch = useDispatch();

  const editCar = async (e) => {
    e.preventDefault();

    if (formData.insuranceType == "5") {
      delete formData.insuranceExpYear;
      delete formData.insuranceExpMonth;
    }

    if (

      !formData.makeId ||
      !formData.fuelType ||
      !formData.transmissionType ||
      !formData?.variantId ||
      !formData.modelId ||
      !formData.makeYear ||
      !formData.listingPrice
    ) {
      setRequiredError(true);
      return;
    }
    setLoading(true);
    setloaderTrue(true);
    try {
      const updatedFormData = {
        ...formData,
       
        regNumber: formData.regNumber ? formData.regNumber : undefined,
        regYear: formData.regYear ? formData.regYear : undefined,
        colour: formData.colour ? formData.colour : undefined,
        regCityId: formData.regCityId ? formData.regCityId : undefined,
        state: formData.state ? formData.state : undefined,
        insuranceExpMonth: formData.insuranceExpMonth ? formData.insuranceExpMonth : undefined,
        insuranceExpYear: formData.insuranceExpYear ? formData.insuranceExpYear : undefined,
        insuranceType: formData.insuranceType ? formData.insuranceType : undefined,
        isAssured: formData.isAssured ? formData.isAssured : undefined,
        insuranceType: formData.insuranceType ? formData.insuranceType : '0',
        is_inventory: "update",
        trending: String(formData.trending),
        discounted: String(formData.discounted),
        newModel: String(formData.newModel),
        newStock: String(formData.newStock),
        isAssured: String(formData.isAssured),
        isClassified: String(formData.isClassified),
        isCngFitted: String(formData.isCngFitted),
        insuranceExpMonth: parseInt(formData.insuranceExpMonth, 10),
        colour: coloList.filter((id) => id.colorName === formData?.colour)?.[0]
          ?.colorId,
        mspPrice:
          Math.ceil(
            (Number(formData?.listingPrice) *
              Number(formData?.discountPercent)) /
              100
          ) + Number(formData?.listingPrice),
        discountPercent: formData.discountPercent
          ? parseInt(formData.discountPercent, 10)
          : 0,
      };

      const response = await carApi.editCarDetails(updatedFormData);

      if (response?.status === true) {
        navigate("/car-management");
      } else {
        setLoading(false);
        setloaderTrue(false);
        response?.message?.map((errr) =>
          toast.error(errr || "Something went wrong.")
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.msg || "Internal Server Error");
      setLoading(false);
      setloaderTrue(false);
    }
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      insuranceExpMonth: selectedMonth,
    }));
  };

  const handleYearChange = (newValue, name) => {
    const year = moment(newValue).year();

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: year,
    }));
  };

  const handleCheckbox = (event, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: event.target.checked ? 1 : 0,
    }));
  };

  console.log("shantam", formData?.colour);

  return (
    <div className={style["container"]}>
      {loaderTrue ? (
        <div class="loading">
          <div class="loader"></div>
        </div>
      ) : null}
      <div className={style["header-wrapper"]}>
        <BackButton
          onClick={() => {
            navigate("/car-management");
          }}
        />
        <PrimaryHeading
          title={"Edit Car Details"}
          fontSize={"1.75rem"}
          fontWeight={600}
        />
      </div>

      <div className={style["wrapper"]}>
        <div className={style["vehicle-details-container"]}>
          {/* <PrimaryHeading
            title={"Vehicle Details"}
            fontSize={"1.5rem"}
            fontWeight={500}
          /> */}
          <SecondaryHeading
            title={"Vehicle Details"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Make <span style={{ color: "red" }}>*</span>
              </p>
              <MultiSelect
                name="makeId"
                handleChange={(e) =>
                  setFormData({ ...formData, makeId: e.value })
                }
                items={brandList?.map((ele) => ({
                  label: ele?.makeName,
                  value: ele?.makeId,
                }))}
                selectedItems={formData?.makeId}
                hint={"Select Make"}
                isSearchable={true}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />
              <ErrorMessage
                errorMessage={!formData?.makeId && "Please select brand"}
                requiredError={
                  !formData?.makeId?.length > 0 ? requiredError : ""
                }
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Make Year <span style={{ color: "red" }}>*</span>
              </p>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="de"
                sx={{
                  "& .MuiStack-root": {
                    paddingTop: "10px !important",
                  },
                }}
              >
                {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                <DatePicker
                  size="small"
                  openTo="year"
                  views={["year"]}
                  value={
                    formData?.makeYear
                      ? moment().year(formData?.makeYear)
                      : null
                  }
                  onChange={(newValue) =>
                    handleYearChange(newValue, "makeYear")
                  }
                  required={true}
                  // className={style["custom-year-picker"]}/
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "36px !important",
                    },
                    "& .MuiStack-root": {
                      paddingTop: "0px !important",
                    },
                  }}
                />
                {/* </DemoContainer> */}
              </LocalizationProvider>
              <ErrorMessage
                errorMessage={!formData.makeYear && "Please select year"}
                requiredError={!formData.makeYear?.length > 0 && requiredError}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Model <span style={{ color: "red" }}>*</span>
              </p>
              <MultiSelect
                name="modelId"
                handleChange={(e) =>
                  setFormData({ ...formData, modelId: e.value })
                }
                items={modelList?.map((ele) => ({
                  label: ele?.modelName,
                  value: ele?.modelId,
                }))}
                selectedItems={formData?.modelId}
                hint={"Select Model"}
                isSearchable={true}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />
              <ErrorMessage
                errorMessage={!formData?.modelId && "Please select model"}
                requiredError={!formData?.modelId?.length > 0 && requiredError}
              />
            </div>
            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Varient <span style={{ color: "red" }}>*</span>
              </p>
              <MultiSelect
                name="variantId"
                handleChange={(e) =>
                  setFormData({ ...formData, variantId: e.value })
                }
                items={varientList?.map((ele) => ({
                  label: ele?.variantName,
                  value: ele?.variantId,
                }))}
                selectedItems={formData?.variantId}
                hint={"Select Varient"}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />
              <ErrorMessage
                errorMessage={!formData?.variantId && "Please select variant"}
                requiredError={
                  !formData?.variantId?.length > 0 && requiredError
                }
              />
            </div>

            <div className={style["input-label"]}>
              <p className={style["label"]} style={{ marginBottom: 10 }}>
                Reg Year <span style={{ color: "red" }}></span>
              </p>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="de"
                sx={{
                  "& .MuiStack-root": {
                    paddingTop: "0px !important",
                  },
                }}
              >
                {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                <DatePicker
                  size="small"
                  openTo="year"
                  views={["year"]}
                  required={true}
                  value={
                    formData.regYear ? moment().year(formData.regYear) : null
                  }
                  onChange={(newValue) => handleYearChange(newValue, "regYear")}
                  // className={style["custom-year-picker"]}/
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "36px !important",
                    },
                    "& .MuiStack-root": {
                      paddingTop: "0px !important",
                    },
                  }}
                />
                {/* </DemoContainer> */}
              </LocalizationProvider>
             
            </div>

            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Vehicle Number <span style={{ color: "red" }}></span>
              </p>
              <InputField
                placeholder={"Vehicle Number"}
                name="regNumber"
                handleChange={handleChange}
                value={formData?.regNumber.toUpperCase()}
                regexPattern={/^(?=[A-Za-z]{2})(?=.*[A-Z]).{2,11}$/}
               
                
              />
            </div>

            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                KM Driven <span style={{ color: "red" }}>*</span>
              </p>
              <InputField
                placeholder={"Enter KM Driven"}
                name="kmDriven"
                handleChange={handleChange}
                value={formData?.kmDriven}
                regexPattern={/^\d+$/}
                errorMessage={
                  formData?.kmDriven
                    ? "Km should be valid"
                    : "Please enter driven km"
                }
                requiredError={!formData?.kmDriven?.length > 0 && requiredError}
                length={6}
              />
            </div>

            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Owner <span style={{ color: "red" }}>*</span>
              </p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="ownerType"
                handleChange={(e) => {
                  setFormData({ ...formData, ownerType: e.target.value });
                }}
                items={[
                  { label: "First", value: 1 },
                  { label: "Second", value: 2 },
                  { label: "Third", value: 3 },
                  { label: "Fourth", value: 4 },
                  { label: "Fourth+", value: 5 },
                ]}
                value={formData?.ownerType}
                hint={"Select Owner"}
                required={true}
              />
              <ErrorMessage
                errorMessage={!formData?.ownerType && "Please select owner"}
                requiredError={
                  !formData?.ownerType?.length > 0 && requiredError
                }
              />
            </div>
            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                State <span style={{ color: "red" }}></span>
              </p>
              <MultiSelect
                name="state"
                handleChange={(e) =>
                  setFormData({ ...formData, state: e.value })
                }
                items={geoRegion.state?.map((ele) => ({
                  label: ele?.stateName,
                  value: ele?.stateListId,
                }))}
                selectedItems={formData?.state}
                hint={"Select State"}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
                required={true}
              />
            
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                City <span style={{ color: "red" }}></span>
              </p>
              <MultiSelect
                name="regCityId"
                handleChange={(e) =>
                  setFormData({ ...formData, regCityId: e.value })
                }
                items={geoRegion.city?.map((ele) => ({
                  label: ele?.cityName,
                  value: ele?.cityId,
                }))}
                selectedItems={formData?.regCityId}
                hint={"Select City"}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
                required={true}
              />
            
            </div>
            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Color <span style={{ color: "red" }}></span>
              </p>
              <MultiSelect
                name="colour"
                handleChange={(e) =>
                  setFormData({ ...formData, colour: e.value })
                }
                items={coloList?.map((ele) => ({
                  label: ele?.colorName,
                  value: ele?.colorName,
                }))}
                selectedItems={formData?.colour}
                hint={"Select Color"}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
                required={true}
              />
             
            </div>
            <div className={style["input-label"]}></div>
            <div className={style["input-label"]}></div>
          </div>

          <SecondaryHeading
            title={"Price"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />
          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Price <span style={{ color: "red" }}>*</span>
              </p>
              <InputField
                placeholder={"Enter Price"}
                name="listingPrice"
                handleChange={handleChange}
                value={
                  formData?.listingPrice
                    ? formatIndianCurrency(formData.listingPrice)
                    : ""
                }
                length={9}
                regexPattern={/^[\d,]+$/}
                errorMessage={
                  formData?.listingPrice
                    ? "Price should be valid"
                    : "Please enter price"
                }
                requiredError={
                  !formData?.listingPrice?.length > 0 && requiredError
                }
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Discount Percent <span style={{ color: "red" }}>*</span>
              </p>
              <InputField
                placeholder={"Enter Discount %"}
                name="discountPercent"
                handleChange={handleChange}
                value={formData?.discountPercent}
                length={3}
                regexPattern={/^\d+$/}
                errorMessage={
                  formData?.discountPercent
                    ? "Discount should be valid"
                    : "Please enter discount price"
                }
                // requiredError={
                //   !formData?.discountPercent?.length > 0 && requiredError
                // }
              />
            </div>
            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Max Selling Price <span style={{ color: "red" }}>*</span>
              </p>
              <InputField
                placeholder={"Enter Selling Price"}
                name="mspPrice"
                handleChange={handleChange}
                value={formatIndianCurrency(
                  Math.ceil(
                    (formData?.listingPrice * formData?.discountPercent) / 100
                  ) + Number(formData?.listingPrice)
                )}
                disabled={true}
              />
            </div>
            <div className={style["input-label"]}></div>
            <div className={style["input-label"]}></div>
          </div>

          <SecondaryHeading
            title={"Other Information"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Fuel Type <span style={{ color: "red" }}>*</span>
              </p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="fuelType"
                handleChange={(e) => {
                  setFormData({ ...formData, fuelType: e.target.value });
                }}
                items={[
                  { label: "Petrol", value: 1 },
                  { label: "Deisel", value: 2 },
                ]}
                value={formData?.fuelType}
                hint={"Select Fuel Type"}
              />
              <ErrorMessage
                errorMessage={!formData?.fuelType && "Please select fuel"}
                requiredError={!formData?.fuelType?.length > 0 && requiredError}
              />
            </div>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Transmission <span style={{ color: "red" }}>*</span>
              </p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="transmissionType"
                handleChange={(e) => {
                  setFormData({
                    ...formData,
                    transmissionType: e.target.value,
                  });
                }}
                items={[
                  { label: "Manual", value: 1 },
                  { label: "Automatic", value: 2 },
                ]}
                value={formData?.transmissionType}
                hint={"Select Transmission"}
              />
              <ErrorMessage
                errorMessage={
                  !formData?.transmissionType && "Please select transmission"
                }
                requiredError={
                  !formData?.transmissionType?.length > 0 && requiredError
                }
              />
            </div>
            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Insurance Type <span style={{ color: "red" }}></span>
              </p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="insuranceType"
                handleChange={(e) => {
                  setFormData({
                    ...formData,
                    insuranceType: e.target.value,
                  });
                }}
                items={[
                  { description: "Comprehensive", id: 1 },
                  { description: "Zero Dep", id: 2 },
                  { description: "Own Damage", id: 3 },
                  { description: "Third Party", id: 4 },
                  { description: "No Insurance", id: 5 },
                ]?.map((ele) => ({
                  label: ele?.description,
                  value: ele?.id,
                }))}
                value={formData?.insuranceType}
                hint={"Select insurance type"}
              />
             
            </div>

            <div className={style["input-label"]}>
              <p className={style["label"]}>Insurance Valid Year</p>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="de"
                sx={{
                  "& .MuiStack-root": {
                    paddingTop: "10px !important",
                  },
                }}
              >
                {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                <DatePicker
                  size="small"
                  openTo="year"
                  views={["year"]}
                  value={
                    formData?.insuranceType !== "5"
                      ? formData.insuranceExpYear
                        ? moment().year(formData.insuranceExpYear)
                        : null
                      : null
                  }
                  onChange={(newValue) =>
                    handleYearChange(newValue, "insuranceExpYear")
                  }
                  required={true}
                  // className={style["custom-year-picker"]}/
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "36px !important",
                    },
                    "& .MuiStack-root": {
                      paddingTop: "0px !important",
                    },
                  }}
                />
                {/* </DemoContainer> */}
              </LocalizationProvider>

             
            </div>

            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Insurance Valid Month</p>

              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                name="insuranceExpMonth"
                handleChange={handleMonthChange}
                items={[
                  { id: 1, name: "January" },
                  { id: 2, name: "February" },
                  { id: 3, name: "March" },
                  { id: 4, name: "April" },
                  { id: 5, name: "May" },
                  { id: 6, name: "June" },
                  { id: 7, name: "July" },
                  { id: 8, name: "August" },
                  { id: 9, name: "September" },
                  { id: 10, name: "October" },
                  { id: 11, name: "November" },
                  { id: 12, name: "December" },
                ]?.map((ele) => ({
                  label: ele?.name,
                  value: ele?.id,
                }))}
                value={
                  formData?.insuranceType !== "5"
                    ? formData?.insuranceExpMonth
                    : ""
                }
                hint={"Select insurance expiry month"}
                required={true}
              />
             
            </div>
            <div className={style["input-label"]}></div>
            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["submit-cont"]}>
              <label>
                <input
                  className={style["input-checkbox"]}
                  type="checkbox"
                  checked={formData.isCngFitted}
                  onChange={(e) => handleCheckbox(e, "isCngFitted")}
                />
                <span style={{ paddingLeft: "10px" }}>Is CNG Fitted</span>
              </label>
            </div>
          </div>

          <SecondaryHeading
            title={"Tags"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>
                Car Status <span style={{ color: "red" }}>*</span>
              </p>
              <SelectableButton
                trailingIcon={R.ic_arrow_down}
                containerStyle={style["selectable-button-container-style"]}
                name="carStatus"
                handleChange={(e) => {
                  setFormData({ ...formData, carStatus: e.target.value });
                }}
                items={[
                  { label: "Active", value: 1 },
                  { label: "InActive", value: 0 },
                  { label: "Booked", value: 2 },
                  { label: "Sold", value: 3 },
                ]}
                value={formData?.carStatus}
                hint={"Select Car Status"}
              />
              <ErrorMessage
                errorMessage={
                  !formData?.carStatus && "Please select car status"
                }
                requiredError={
                  !formData?.carStatus?.length > 0 && requiredError
                }
              />
            </div>
            <div className={style["input-label"]}></div>
            <div className={style["input-label"]}></div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["flex-col"]}>
              <p className={style["label"]}>Flags</p>
              <div className={style["flex-row"]}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.trending}
                    onChange={(e) => handleCheckbox(e, "trending")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Trending</span>
                </label>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.discounted}
                    onChange={(e) => handleCheckbox(e, "discounted")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Discounted</span>
                </label>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.newModel}
                    onChange={(e) => handleCheckbox(e, "newModel")}
                  />
                  <span style={{ paddingLeft: "10px" }}>New Model</span>
                </label>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.newStock}
                    onChange={(e) => handleCheckbox(e, "newStock")}
                  />
                  <span style={{ paddingLeft: "10px" }}>New Stock</span>
                </label>
              </div>
            </div>
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <div className={style["flex-col"]}>
                {/* <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.isAssured}
                    onChange={(e) => handleCheckbox(e, "isAssured")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Is Assured</span>
                </label> */}
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.isClassified}
                    onChange={(e) => handleCheckbox(e, "isClassified")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Is Classified</span>
                </label>
              </div>
            </div>
          </div>

          <SecondaryHeading
            title={"Marketing Data"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}>
              <p className={style["label"]}>Car Description</p>
              <textarea
                style={{
                  padding: "10px",
                  border: "1px solid var(--border-gray)",
                  borderRadius: "7px",
                }}
                placeholder="car Description"
                name="carDescription"
                onChange={handleChange}
                value={formData?.carDescription}
                className={style["textarea"]}
                required={true}
              />
              {/* <ErrorMessage
                errorMessage={
                  !formData?.carDescription && "Please enter description"
                }
                requiredError={
                  !formData?.carDescription?.length > 0 && requiredError
                }
              /> */}
            </div>

            <div className={style["input-label"]}>
              <p className={style["label"]}>Meta Tag</p>
              <textarea
                style={{
                  padding: "10px",
                  border: "1px solid var(--border-gray)",
                  borderRadius: "7px",
                }}
                placeholder="Meta Tag"
                name="metaTag"
                onChange={handleChange}
                value={formData?.metaTag}
                className={style["textarea"]}
                required={true}
              />
              {/* <ErrorMessage
                errorMessage={!formData?.metaTag && "Please enter meta tag"}
                requiredError={!formData?.metaTag?.length > 0 && requiredError}
              /> */}
            </div>
            <div className={style["input-label"]}></div>
            {/* <div className={style["input-label"]}>
              <p className={style["label"]}>Flags</p>
              <MultiSelect
                containerStyle={style["selectable-button-container-style"]}
                name="flags"
                handleChange={(e) => {
                  console.log(e, "fg");
                  setFlags(e);
                }}
                items={[
                  { label: "TRENDING", value: "TRENDING" },
                  { label: "DISCOUNTED", value: "DISCOUNTED" },
                  { label: "NEWMODEL", value: "NEWMODEL" },
                  { label: "NEWSTOCK", value: "NEWSTOCK" },
                ]}
                selectedItems={flags}
                hint={"Select Flags"}
                isMulti={true}
              />
            </div> */}
          </div>

          <div className={style["two-tabs-section"]}>
            <div className={style["input-label"]}></div>
            <div className={style["input-label"]}>
              <div className={style["submit-btn"]}>
                <PrimaryButton
                  name={loading ? "Loading..." : "Save"}
                  fontSize={"1rem"}
                  containerStyle={style["primary-button-container-style"]}
                  onClick={editCar}
                />
              </div>
            </div>
            <div className={style["input-label"]}></div>
          </div>
        </div>

        {/* <div className={style["vehicle-images-container"]}>
          <PrimaryHeading
            title={"Upload Vehicle Images"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style["images-wrapper"]}>
            <ImageCard
              text={"Upload Front Diagonal View - LHS"}
              apiKey={"front_diaog_left"}
              imagesData={imagesData}
              setImagesData={setImagesData}
              previewImage={alreadyImg}
            />
            {imageTypeEnum &&
              imageTypeEnum.length > 0 &&
              imageTypeEnum.map((eachImageType) => {
                return (
                  <ImageCard
                    text={`Upload ${eachImageType.name}`}
                    apiKey={eachImageType.slug}
                    imagesData={imagesData}
                    setImagesData={setImagesData}
                    previewImage={alreadyImg}
                  />
                );
              })}
          </div>
        </div> */}
      </div>
    </div>
  );
}
