import React, { Component, useState } from "react";
import "./Signin.css";
import { API_URL } from "../../config";
import SignIn from "../../image/sign-In-screen.png";
import logo from "../../image/logo.png"

const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!username || !password){
      alert("Pls check username and password");
    }
    fetch(API_URL+"/login",{
      method:"POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({email:username, password: password})
    }).then(res=>res.json())
    .then(res=>{
      if(res && res.status){
        localStorage.setItem("accessToken",res?.token?.accessToken)
        localStorage.setItem("dealerId",res?.info?.dealerId)
        localStorage.setItem("logo",res?.info?.logo)

        localStorage.setItem("loginstatus", true);
        localStorage.setItem("email", username);
        localStorage.setItem("userid", res?.info?.id);
        window.location.href = "/";
      }else{
        alert(res.message)
      }
    })
    return;
  };

  return (


    <div className="container-first">
      <div className="container-serials">
        <div className="container-second">
            <div className="first-container">
                <img src={SignIn} alt="SignIn" loading="eager"/>
            </div>

            <div className="second-container">

            <form onSubmit={handleSubmit}>
              <div className="hadle-site">
                <div className="logo-png">
                    <img src={logo} alt="motor-logo" width={250}/>
                </div>
                  <h1 className="heding-sign-in">Sign in </h1>
                  <p className="para-one">Enter your email and password to sign in!</p>
                    <div className="text_area">
                        <div className="label-second">
                          <label>
                            Email <span className="span-class">*</span>
                          </label>
                        </div>
                        <input
                          type="text"
                          id="username"
                          name="Email"
                          defaultValue=""
                          className="text_input"
                          placeholder="Email"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="label-second">
                          <label>
                            Password <span className="span-class">*</span>
                          </label>
                        </div>
                        
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="password"
                          className="text_input"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                </div>
                <input type="submit" value="LOGIN" className="btn" />
              </form>
            </div>
        </div>
        </div>
    </div>
  );
};

export default Signin;



