import React, { Fragment, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import style from "./detailTable.module.scss";
import { useNavigate } from "react-router-dom";
import { R } from "../../constants/resources";
import { CircularProgress, Switch } from "@mui/material";
import { carApi } from "../../api/carApi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import MultiSelect from "../buttons/multiSelect";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DetailTable({ data = [], setData }) {
  const [loading, setLoading] = useState({});
  const [open, setOpen] = useState(false);
  const [handleBackDrop, setHandleBackDrop] = useState(false);
  const [copyTooltipOpen, setCopyTooltipOpen] = useState({});
  const [dialogBox, setDialogBox] = useState({
    id: "",
    titleMessage: "",
    confirmMessage: "",
    handleConfirm: () => {},
  });

  const columns = [
    {
      accessorKey: "imagesDetails",
      header: "",
      enableColumnFilter: false,
    },
    { accessorKey: "carSkuId", header: "Sku Id" },
    { accessorKey: "make", header: "Make" },
    { accessorKey: "modalName", header: "Model" },
    { accessorKey: "varientName", header: "Variant" },
    { accessorKey: "makeYear", header: "Year" },
    { accessorKey: "carPrice", header: "Price" },
    { accessorKey: "reg_no", header: "Vehicle No." },
    { accessorKey: "status", header: "Status" },
    {
      accessorKey: "action",
      header: "Action",
      enableColumnFilter: false,
    },
  ];

  const handleTooltipClose = (carSkuId) => {
    setCopyTooltipOpen((prev) => ({ ...prev, [carSkuId]: false }));
  };

  const handleTooltipOpen = (carSkuId) => {
    navigator.clipboard
      .writeText(carSkuId)
      .then(() => {
        setCopyTooltipOpen((prev) => ({
          ...prev,
          [carSkuId]: true,
        }));
        setTimeout(() => {
          setCopyTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleClickOpen = (data) => {
    const carSkuId = data?.data?.carSkuId;
    setDialogBox({
      id: carSkuId,
      titleMessage: "! Warning",
      confirmMessage: "Are you sure you want to delete this vehicle!",
      handleConfirm: () => handleDelete(carSkuId),
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectStatus = (value, carSkuId) => {
    console.log("value", value);
    if (value) {
      setDialogBox({
        id: carSkuId,
        titleMessage: "! Warning",
        confirmMessage: "Do you want to update the status!",
        handleConfirm: () => handleConfirmChange(value, carSkuId),
      });
      setOpen(true);
    } else {
      setData((prevData) =>
        prevData.map((car) =>
          car.carSkuId === carSkuId ? { ...car, car_status: "" } : car
        )
      );
    }
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const navigate = useNavigate();
  const onEditClick = (data, link) => {
    if (link === "upload-images") {
      navigate(`/${link}`, { state: data?.data?.carSkuId });
    } else {
      navigate(`/${link}`, { state: data });
    }
  };

  const handleSwitchBtn = async (e, data, name) => {
    const carSkuId = data?.data?.carSkuId;
    const value = e.target.checked ? "1" : "0";

    setLoading((prev) => ({ ...prev, [carSkuId]: { [name]: true } }));

    setData((prevData) =>
      prevData.map((car) =>
        car.carSkuId === carSkuId ? { ...car, [name]: value } : car
      )
    );

    if (name === "is_classified") {
      name = "isClassified";
    }

    try {
      await carApi.editCarDetails({
        carSkuId,

        [name]: value,
        is_inventory: "update",
      });
    } catch (error) {
      console.error("Error updating car details", error);
    } finally {
      setLoading((prev) => ({ ...prev, [carSkuId]: { [name]: false } }));
    }
  };

  const handleDelete = async (carSkuId) => {
    setHandleBackDrop(true);
    try {
      await carApi.editCarDetails({
        carSkuId,
        carStatus: "0",
        is_inventory: "update",
      });
    } catch (error) {
      console.error("Error deleting car", error);
    } finally {
      setData((prevData) =>
        prevData.map((car) =>
          car.carSkuId === carSkuId ? { ...car, car_status: "0" } : car
        )
      );
      handleClose();
      setHandleBackDrop(false);
    }
  };

  const handleConfirmChange = async (value, carSkuId) => {
    if (value && carSkuId) {
      setHandleBackDrop(true);
      handleClose();
      try {
        await carApi.editCarDetails({
          carSkuId,
          carStatus: value,
          is_inventory: "update",
        });
      } catch (error) {
        console.error("Error deleting car", error);
      } finally {
        setData((prevData) =>
          prevData.map((car) =>
            car.carSkuId === carSkuId ? { ...car, car_status: value } : car
          )
        );
        setHandleBackDrop(false);
      }
    }
  };

  const Action = (data) => {
    const carSkuId = data?.data?.carSkuId;
    const isClassifiedLoading = loading[carSkuId]?.is_classified;
    const isAssuredLoading = loading[carSkuId]?.isAssured;
    const isDeleteLoading = loading[carSkuId]?.delete;
    return (
      <div className={style["action-container"]}>
        <div className={style["flex-col-between"]}>
          <div className={style["flex-gap"]}>
            <IconButton
              onClick={() => onEditClick(data, "edit-car-details")}
              title="Edit"
            >
              <EditIcon />
            </IconButton>

            {isDeleteLoading ? (
              <div className={style["text-center"]}>
                <CircularProgress size={20} />
              </div>
            ) : (
              <IconButton onClick={() => handleClickOpen(data)} title="Delete">
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          <div>
            {isClassifiedLoading ? (
              <div className={style["text-center"]}>
                <CircularProgress size={20} />
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  title="isClassified"
                  className={style["input-checkbox"]}
                  type="checkbox"
                  checked={data?.data?.is_classified === "1"}
                  onChange={(e) => handleSwitchBtn(e, data, "is_classified")}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span style={{ paddingLeft: "10px" }}>
                  {data?.data?.is_classified === "1"
                    ? "Classified"
                    : "Classified"}
                </span>
              </div>
            )}

            {isAssuredLoading ? (
              <div className={style["text-center"]}>
                <CircularProgress size={20} />
              </div>
            ) : (
              <div style={{ display: "none", alignItems: "center" }}>
                {/* display :"flex" */}
                <input
                  title="isAssured"
                  className={style["input-checkbox"]}
                  type="checkbox"
                  checked={data?.data?.isAssured}
                  onChange={(e) => handleSwitchBtn(e, data, "isAssured")}
                />
                <span style={{ paddingLeft: "10px" }}>
                  {data?.data?.isAssured ? "Assured" : "Assured"}
                </span>
              </div>
            )}
          </div>
          <div>
            <span
              title="My Website"
              className={style["w-logo"]}
              onClick={() => window.open(data.data.dealer_vdp_link, "_blank")}
            ></span>
            <img
              title="View on MotorPedia"
              className={style["mp-logo"]}
              src={R.img_mp_logo}
              alt="ic_edit"
              onClick={() =>
                window.open(data.data.classifieds_vdp_link, "_blank")
              }
            />
          </div>
        </div>
      </div>
    );
  };
  const CarImg = (data) => (
    <Fragment>
      <div className={style["car-image-box"]}>
        <img
          className={style["car-image"]}
          src={data?.data?.imagesDetails?.[0]?.imgUrls || R.img_nocarfound}
          alt="ic_edit"
          onClick={() => onEditClick(data, "upload-images")}
        />
      </div>
      <div
        className={style["add-icon-parent"]}
        onClick={() => onEditClick(data, "upload-images")}
      >
        <p style={{ paddingRight: "10px" }}>View</p>
        <img
          title="Upload Image"
          className={style["add-icons"]}
          src={R.ic_add}
          alt="ic_edit"
        />
      </div>
    </Fragment>
  );

  const Status = React.memo((data) => {
    const carSkuId = data?.data?.carSkuId;
    const currentStatus = data?.data?.car_status;
    const items = [
      { label: "Active", value: "1" },
      { label: "InActive", value: "0" },
      { label: "Booked", value: "2" },
      { label: "Sold", value: "3" },
    ];

    const selectedStatus = items.filter((item) => item.value === currentStatus);
    return (
      <Fragment>
        <div
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <MultiSelect
            name="status"
            handleChange={(e) => handleSelectStatus(e?.value, carSkuId)}
            items={items}
            selectedItems={selectedStatus}
            hint={"All Status"}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
        </div>
        {/* <div className={style["flex-gap"]}>
          <img
            className={style["status-icon"]}
            src={R.ic_checked_green}
            alt="ic_checked_green"
            onClick={() => handleStatus(carSkuId, "save")}
          />
          <img
            className={style["status-icon"]}
            src={R.ic_cross_red}
            alt="ic_cross_red"
            onClick={() => handleStatus(carSkuId, "reset")}
          />
        </div> */}
      </Fragment>
    );
  });

  const SkuId = ({ data }) => {
    const carSkuId = data?.carSkuId;
    const splitCarSkuId = carSkuId.split("-")[0];
    return (
      <div className={style["flex-between"]}>
        <p>{splitCarSkuId}...</p>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={copyTooltipOpen[carSkuId] || false}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied!"
          >
            <IconButton
              onClick={() => handleTooltipOpen(carSkuId)}
              sx={{ mt: -1 }}
            >
              <ContentCopySharpIcon fontSize="small" titleAccess={carSkuId} />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      </div>
    );
  };

  return (
    <div
      className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
    >
      <table
        className={`${style["table-wrapper"]} table table-border table-hover`}
        cellSpacing={"5px"}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr
                key={headerGroup?.id}
                className={style["table-header-wrapper"]}
              >
                {headerGroup?.headers?.map((header) => {
                  return (
                    <th key={header?.id} className={style["table-header"]}>
                      {header?.column?.columnDef?.header}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr key={row?.id} className={style["table-row"]}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {cell?.column?.columnDef?.accessorKey ===
                      "imagesDetails" ? (
                        <CarImg data={row?.original} />
                      ) : cell?.column?.columnDef?.accessorKey ===
                        "carSkuId" ? (
                        <SkuId data={row?.original} />
                      ) : cell?.column?.columnDef?.accessorKey === "action" ? (
                        <Action data={row?.original} />
                      ) : cell?.column?.columnDef?.accessorKey === "status" ? (
                        <Status data={row?.original} />
                      ) : (
                        flexRender(
                          cell?.column?.columnDef?.cell,
                          cell?.getContext()
                        )
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <CustomizedDialogs
        open={open}
        handleClose={handleClose}
        rowSkuId={dialogBox.id}
        titleMessage={dialogBox.titleMessage}
        confirmMessage={dialogBox.confirmMessage}
        handleConfirm={dialogBox.handleConfirm}
      />
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={handleBackDrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export function CustomizedDialogs({
  open,
  handleClose,
  rowSkuId,
  handleDelete,
  titleMessage,
  confirmMessage,
  handleConfirm,
}) {
  return (
    <React.Fragment>
      {/* <Button variant="outlined">Open dialog</Button> */}
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          width={500}
          sx={{ paddingX: "16px" }}
        >
          {titleMessage}
        </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent>
          <Typography gutterBottom width={500} fontSize={18}>
            {confirmMessage}
          </Typography>
          {/* <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
            dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            sx={{
              color: "#4462de",
              ":hover": { color: "#4462de" },
              textTransform: "none",
              letterSpacing: "0.7px",
            }}
            onClick={handleConfirm}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            size="large"
            sx={{
              color: "#4462de",
              ":hover": { color: "#4462de" },
              textTransform: "none",
              letterSpacing: "0.7px",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
