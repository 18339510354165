import React from "react";
import style from "./selectableButton.module.scss";

export default function SelectableButton({
  options,
  trailingIcon,
  containerStyle,
  handleChange,
  name,
  value,
  disabled,
  hint,
  items,
}) {
  return (
    <label
      htmlFor="select"
      className={[style["container"], containerStyle].join(" ")}
    >
      <select
        value={value}
        onChange={handleChange}
        disabled={disabled}
        className={style["select-tag"]}
      >
        <option value="">{hint}</option>
        {items?.map((item, index) => {
          return (
            <option className={style["option"]} key={index} value={item.value}>
              {item.label}
              {item.subLabel ? item.sublabel : ""}
            </option>
          );
        })}
        {/* {total > itemlength && (
          <p onClick={() => setPage((page) => page + 1)}>Load More</p>
        )} */}
      </select>
      {trailingIcon && (
        <div className={style["down-arrow-box"]}>
          <img
            src={trailingIcon}
            className={style["down-arrow-icon"]}
            alt={"down-arrow"}
          />
        </div>
      )}
    </label>
  );
}
