import React, { useState, useEffect } from "react";
import style from "./buyerLead.module.scss";
import BuyerEditLeads from "./components/buyer-edit-leads/BuyerEditLeads";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SelectableButton from "../../components/buttons/SelectableButton";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import BuyerTable from "./components/table/BuyerTable";
import { useDispatch, useSelector } from "react-redux";
import { getBuyerList } from "../../store/buyerLead/buyerLeadAction";

export default function BuyerLead() {
  const { buyerLeadList, total, totalRecords, hasNext } = useSelector((state) => state.buyerLead);


  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState()
  const [status, setStatus] = useState("");
  const handleChange = (e) => {
    setSearchText(e.target.value);
    setPagination({ ...pagination, pageNo: 0 }); // Reset to first page on search
  };

  const handleSearch = () =>{
    let filtered = buyerLeadList;
    if (searchText) {
      filtered = filtered.filter(items =>
        items.name.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
        items.phone.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );
    }
    setFilterData(filtered);
  }

  useEffect(()=>{
      handleSearch();
  },[searchText, buyerLeadList])

  useEffect(() => {
    dispatch(getBuyerList());
  }, []);



  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    pageSize: 15,
    pageNo: 0,
  });

  console.log('pageSize ==>', pagination.pageNo);

  const exportToExcel = () => {
    const dataWithoutAction = buyerLeadList.map(({ action, ...rest }) => rest);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithoutAction);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "buyer_data.xlsx");
    });
  };


  useEffect(() => {
    fetchBuyerLeads(pagination.pageNo);
  }, [pagination.pageNo, pagination.pageSize, searchText, status]);

  const fetchBuyerLeads = (pageNo) => {
    console.log("status ", status);
    const params = {
      pageNo: pageNo + 1, // Ensure the API receives the correct page number
      pageSize: pagination.pageSize,
      search: searchText || undefined,
      leadStatus: status || undefined,
    };
    dispatch(getBuyerList(params));
  };


  // useEffect(() => {
  //   dispatch(
  //     getBuyerList({
  //       search: searchText || undefined,
  //       leadStatus: status || undefined,
  //     })
  //   );
  // }, [searchText, status]);


  const handleNextPage = () => {
    if (hasNext) {
      setPagination((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pagination.pageNo > 0) {
      setPagination((prev) => ({ ...prev, pageNo: prev.pageNo - 1 }));
    }
  };


  return (
    <div className={style["container"]}>
      <PrimaryHeading
        title={"Buyer Lead Management"}
        fontSize={"1.75rem"}
        fontWeight={600}
        count={totalRecords}
      />

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Buyer Name or Phone"}
            handleChange={handleChange}
          />
          <SelectableButton
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            handleChange={(e) => {
              console.log("Selected Value:", e.target.value); // Logs the selected value
              setStatus(e.target.value); // Updates the status
            }}
            items={[
              { label: "New", value: 1 },
              { label: "Follow Ups", value: 2 },
              { label: "Interested", value: 3 },
              { label: "Walkin Schedule", value: 4 },
              { label: "Walkin Done", value: 5 },
              { label: "Booked", value: 6 },
              { label: "Converted", value: 7 },
              { label: "Not Interested", value: 8 },
              { label: "Closed", value: 9 },
              { label: "Done", value: 10 },
            ]}
            hint={"All Status"}
            value={status}
          />
        </div>

        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["excel-button-container-style"]}
            leadingIcon={R.ic_excel}
            name={"Export Excel"}
            fontSize={"1rem"}
            onClick={exportToExcel}
          />
        </div>
      </div>
      <BuyerTable
        data={buyerLeadList}
        pagination={pagination}
        setPagination={setPagination}
        total={total}
      />


      <div className={style["pagination-action-wrapper"]}>
        <button onClick={handlePreviousPage} disabled={hasNext === 0}>
          Back
        </button>
        <p>
          {/* Page {currentPage} of {totalPageCount} */}
          Page {pagination.pageNo + 1}
        </p>

        <button onClick={handleNextPage} disabled={!hasNext}>
          Next
        </button>
      </div>

    </div>
  );
}
