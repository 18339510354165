import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  let auth = localStorage.getItem("accessToken");
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;



export const formatIndianCurrency = (amount) => {
  if (!amount) return "";

  const x = amount.toString().replace(/\D/g, "");

  const lastThree = x.substring(x.length - 3);
  const otherNumbers = x.substring(0, x.length - 3);

  const formattedValue =
    otherNumbers !== ""
      ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
      : lastThree;

  return formattedValue;
};



// Other existing exports
export { PrivateRoutes };
