export default function SecondaryHeading({ title, fontSize, fontWeight }) {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {/* Left line */}
      <div
        style={{
          flexGrow: 1, // Fills the space on the left
          height: "1px",
          backgroundColor: "var(--heading-gray)", // Line color
          marginRight: "10px", // Space between line and text
        }}
      ></div>

      {/* Text */}
      <p
        style={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: "var(--heading-gray)",
          margin: 0, // Remove default margin
          position: "relative",
          flexGrow: 0,
        }}
      >
        {title}
      </p>

      {/* Right line */}
      <div
        style={{
          flexGrow: 50, // Fills the remaining space on the right
          height: "1px",
          backgroundColor: "var(--heading-gray)", // Line color
          marginLeft: "10px", // Space between text and line
        }}
      ></div>
    </div>
  );
}
