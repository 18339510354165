import { CREATE_CAR_DETAIL, EDIT_CAR_DETAIL, GET_CAR_LIST } from "../types";
import { carApi } from "../../api/carApi";

export const postCarDetails = (payload) => {
  return carApi.postCarDetails(payload).then(
    (data) => {
      // Dispatch is no longer needed since you're bypassing Redux
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

// export const getCarList = (params) => (dispatch, getState) => {
//   //   const { buyerLeadList } = getState().buyerLead;
//   return carApi.getCarList(params).then(
//     (response) => {
//       // console.log('response-carlist -->', response?.totalPageCount)
//       dispatch({
//         type: GET_CAR_LIST,
//         payload: {
//           data: response?.data,
//           currentPage : response?.currentPage,
//           totalPageCount : response?.totalPageCount,
//           totalCount : response?.totalCount,
//           hasNextPage : response?.hasNextPage
//           // // buyerLeadList && params?.page > 1
//           // //   ? [...buyerLeadList, ...response?.data]
//           // //   : response?.data,
//           // total: response?.total,
//         },
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };

export const getCarList = (params) => {
  // console.log("params ", params);
  return carApi.getCarList(params).then(
    (response) => {
      // console.log("checkRespnse checkRespnse checkRespnse", response.data);
      // console.log("paginationpagination ", response.pagination)
      return {
        type: GET_CAR_LIST,
        payload: {
          data: response?.data,
          currentPage: response?.pagination.cur_page,
          totalPageCount: response?.pagination.total_page,
          totalCount: response?.pagination.total,
          hasNextPage: response?.pagination.next_page,
          hasPrevPage: response?.pagination.prev_page
        },
      };
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const editCarDetails = async (payload) => {
  try {
    // Directly send the payload as a JSON object in the request body
    const response = await carApi.editCarDetails(payload);
    return Promise.resolve(response);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};
