import { GET_CAR_LIST, CREATE_CAR_DETAIL, EDIT_CAR_DETAIL } from "../types";

const initialState = {
  carList: [],
  total: 10,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CAR_DETAIL:
      //   const updateBuyerLead = state.buyerLeadList.map((buyerLead) => {
      //     if (buyerLead.id === payload.id) {
      //       return { ...buyerLead, ...payload };
      //     }
      //     return buyerLead;
      //   });
      return {
        ...state,
        carList: payload,
      };
    case EDIT_CAR_DETAIL:
      const updateBuyerLead = state.carList.map((buyerLead) => {
        if (buyerLead.car_id === payload.car_id) {
          return { ...buyerLead, ...payload };
        }
        return buyerLead;
      });
      return {
        ...state,
        carList: updateBuyerLead,
      };
    case GET_CAR_LIST:
      return {
        ...state,
        carList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
