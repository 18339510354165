import React, { Fragment } from "react";
import style from "./inputField.module.scss";

export default function ErrorMessage({ requiredError, errorMessage }) {
  return (
    <Fragment>
      {requiredError && (
        <span className={style["error-msg"]}>{errorMessage}</span>
      )}
    </Fragment>
  );
}
