import React, { useState } from 'react';
import './dateField.css'; // Import the CSS file

const DatePicker = ({placeholder, handleChange, name, value}) => {

  return (
    <div className="date-picker-container">

      <input 
        type="date" 
        id="date-picker" 
        value={value} 
        name={name}
        onChange={handleChange} 
        placeholder={placeholder}
        className="date-picker-input"
      />

    </div>
  );
};

export default DatePicker;
