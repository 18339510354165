import React, { useEffect, useState } from "react";
import style from "./buyerEditLeads.module.scss";
import BackButton from "../../../../components/buttons/BackButton";
import PrimaryHeading from "../../../../components/primary-heading/PrimaryHeading";
import SelectableButton from "../../../../components/buttons/SelectableButton";
import { R } from "../../../../constants/resources";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import PriceRange from "../price-range/PriceRange";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../../../components/input-field/InputField";
import { publicApi } from "../../../../api/publicApi";
import { editBuyerDetails } from "../../../../store/buyerLead/buyerLeadAction";
import { useDispatch } from "react-redux";
export default function BuyerEditLeads() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [formData, setFormData] = useState({
    brand_id: state?.data?.brand_id || null,
    budget_price: state?.data?.budget_price || null,
    car_id: state?.data?.car_id || null,
    email: state?.data?.email || null,
    lead_id: state?.data?.lead_id || null,
    model_id: state?.data?.model_id || null,
    name: state?.data?.name || null,
    phone_no: state?.data?.phone_no || null,
    status: state?.data?.status || null,
    variant_id: state?.data?.variant_id || null,
  });

  useEffect(() => {
    setFormData({
      brand_id: state?.data?.brand_id,
      budget_price: state?.data?.budget_price,
      car_id: state?.data?.car_id,
      email: state?.data?.email,
      lead_id: state?.data?.lead_id,
      model_id: state?.data?.model_id,
      name: state?.data?.name,
      phone_no: state?.data?.phone_no,
      status: state?.data?.status,
      variant_id: state?.data?.variant_id,
    });
  }, [state]);

  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);

  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const models = async () => {
    try {
      const res = await publicApi.modelList({ make: formData?.brand_id });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      const res = await publicApi.varientList({ model: formData?.model_id });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    brands();
  }, []);

  useEffect(() => {
    if (formData?.brand_id) models();
  }, [formData?.brand_id]);

  useEffect(() => {
    if (formData?.model_id) varients();
  }, [formData?.model]);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const editCar = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(editBuyerDetails(formData))
      .then((res) => {
        console.log(res);
        setLoading(false);

        navigate("/buyer-lead-management");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className={style["container"]}>
      <div className={style["header-wrapper"]}>
        <BackButton onClick={() => navigate("/buyer-lead-management")} />
        <PrimaryHeading title={"Edit Leads"} />
      </div>

      <div className={style["body-wrapper"]}>
        <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Update Status</p>
            <SelectableButton
              containerStyle={style["selectable-button-container-style"]}
              trailingIcon={R.ic_arrow_down_blue}
              items={[
                { label: "Booked", value: "booked" },
                { label: "Already Bought", value: "already_bought" },
                { label: "Not Interested", value: "not_interested" },
                { label: "Loan Not Approved", value: "loan_not_approved" },
                { label: "Pending", value: "PENDING" },
                { label: "Done", value: "Done" },
              ]}
              value={formData?.status}
            />
          </div>
        </div>
        <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Update Brand</p>
            <SelectableButton
              trailingIcon={R.ic_arrow_down}
              containerStyle={style["selectable-button-container-style"]}
              name="brand_id"
              handleChange={(e) => {
                setFormData({ ...formData, brand_id: e.target.value });
              }}
              items={brandList?.map((ele) => ({
                label: ele?.brand_name,
                value: ele?.id,
              }))}
              value={formData?.brand_id}
              hint={"Select Make"}
            />
          </div>
        </div>
        <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Model</p>
            <SelectableButton
              trailingIcon={R.ic_arrow_down}
              containerStyle={style["selectable-button-container-style"]}
              name="model_id"
              handleChange={(e) => {
                setFormData({ ...formData, model_id: e.target.value });
              }}
              items={modelList?.map((ele) => ({
                label: ele?.description,
                value: ele?.id,
              }))}
              value={formData?.model_id}
              hint={"Select Model"}
            />
          </div>
        </div>
        <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Variant</p>
            <SelectableButton
              trailingIcon={R.ic_arrow_down}
              containerStyle={style["selectable-button-container-style"]}
              name="variant_id"
              handleChange={(e) => {
                setFormData({ ...formData, variant_id: e.target.value });
              }}
              items={varientList?.map((ele) => ({
                label: ele?.description,
                value: ele?.id,
              }))}
              value={formData?.variant_id}
              hint={"Select Varient"}
            />
          </div>
        </div>
        <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Phone</p>
            <InputField
              placeholder={"Phone"}
              handleChange={(e) =>
                setFormData({ ...formData, phone_no: e.target.value })
              }
              name={"phone_no"}
              value={formData?.phone_no}
            />
          </div>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Budget Price</p>
            <InputField
              placeholder={"Price"}
              handleChange={(e) =>
                setFormData({ ...formData, budget_price: e.target.value })
              }
              name={"budget_price"}
              value={formData?.budget_price}
            />
          </div>
        </div>

        <PrimaryButton
          onClick={editCar}
          name={loading ? "Loading..." : "Submit"}
          fontSize={"1.5rem"}
          containerStyle={style["primary-button-container-style"]}
        />
      </div>
    </div>
  );
}
