import { call } from ".";

class PublicApi {
  brandList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "inventoryService/public/get-make", null, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getColors() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "inventoryService/public/get-color", null, null);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  modelList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "inventoryService/public/get-model", params, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  varientList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "inventoryService/public/get-variant", params, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const publicApi = new PublicApi();
