import React, { useEffect, useState } from "react";
import style from "./sellerEditLeads.module.scss";
import BackButton from "../../../../components/buttons/BackButton";
import PrimaryHeading from "../../../../components/primary-heading/PrimaryHeading";
import { R } from "../../../../constants/resources";
import SelectableButton from "../../../../components/buttons/SelectableButton";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import InputField from "../../../../components/input-field/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import { editSellerDetails } from "../../../../store/sellerLead/sellerLeadAction";
import { useDispatch } from "react-redux";

export default function SellerEditLeads() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formData, setFormData] = useState({
    leadStatus: state?.data?.leadStatus || null,
    phone_no: state?.data?.phone_no || null,
    quote_price: state?.data?.quote_price || null,
    seller_name: state?.data?.seller_name || null,
    selling_price: state?.data?.selling_price || null,
    lead_id: state?.data?.lead_id,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      leadStatus: state?.data?.leadStatus,
      phone_no: state?.data?.phone_no,
      quote_price: state?.data?.quote_price,
      seller_name: state?.data?.seller_name,
      selling_price: state?.data?.selling_price,
      lead_id: state?.data?.lead_id,
    });
  }, [state]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const editSeller = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(editSellerDetails(formData))
      .then((res) => {
        console.log(res);
        navigate("/seller-lead-management");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className={style["container"]}>
      <div className={style["header-wrapper"]}>
        <BackButton
          onClick={() => {
            navigate("/seller-lead-management");
          }}
        />
        <PrimaryHeading title={"Edit Leads"} />
      </div>

      <div className={style["body-wrapper"]}>
        <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Seller Name</p>
            <InputField
              placeholder={"Seller Name"}
              name="seller_name"
              handleChange={(e) =>
                setFormData({ ...formData, seller_name: e.target.value })
              }
              value={formData?.seller_name}
            />
          </div>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Seller Phone</p>
            <InputField
              placeholder={"Seller Phone"}
              name="phone_no"
              handleChange={(e) =>
                setFormData({ ...formData, phone_no: e.target.value })
              }
              value={formData?.phone_no}
            />
          </div>
        </div>

        <div className={style["select-box"]}>
          <p className={style["label"]}>Update Status</p>
          <SelectableButton
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            name="leadStatus"
            handleChange={(e) => {
              setFormData({ ...formData, leadStatus: e.target.value });
            }}
            items={[
              { label: "Active", value: "ACTIVE" },
              { label: "Pending", value: "PENDING" },
            ]}
            value={formData?.leadStatus}
            hint={"Update Status"}
          />
        </div>
        {/* <div className={style["select-box"]}>
          <p className={style["label"]}>Model</p>
          <SelectableButton
            containerStyle={style["selectable-button-container-style"]}
            trailingIcon={R.ic_arrow_down_blue}
            options={["Hyundai", "Maruti", "Kia"]}
          />
        </div>

        <div className={style["select-box"]}>
          <p className={style["label"]}>Variant</p>
          <SelectableButton
            containerStyle={style["selectable-button-container-style"]}
            trailingIcon={R.ic_arrow_down_blue}
            options={["i10", "Baleno", "Scorpio"]}
          />
        </div> */}

        {/* <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Phone</p>
            <InputField placeholder={"Phone"} />
          </div>
        </div> */}

        <div className={style["price-section"]}>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Seller Price</p>
            <InputField
              placeholder={"Price"}
              name="selling_price"
              handleChange={(e) =>
                setFormData({ ...formData, selling_price: e.target.value })
              }
              value={formData?.selling_price}
            />
          </div>
          <div className={style["price-box"]}>
            <p className={style["label"]}>Quote Price</p>
            <InputField
              placeholder={"Price"}
              name="quote_price"
              handleChange={(e) =>
                setFormData({ ...formData, quote_price: e.target.value })
              }
              value={formData?.quote_price}
            />
          </div>
        </div>

        {/* <p className={style["label"]}>Assign To</p>
        <SelectableButton
          containerStyle={style["selectable-button-container-style"]}
          trailingIcon={R.ic_arrow_down_blue}
          options={["Naman Sharma", "Aman Sharma", "Amit Sharma"]}
        /> */}

        <PrimaryButton
          onClick={editSeller}
          name={loading ? "Loading..." : "Submit"}
          fontSize={"1.5rem"}
          containerStyle={style["primary-button-container-style"]}
        />
      </div>
    </div>
  );
}
