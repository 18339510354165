import { call, carCall, dealerSaveInventory, carCallImage } from ".";
import { dealerId } from "../constants/resources";

class CarApi {
  editCarDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/save",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  uploadCarImages(data) {
    const token = localStorage.getItem("accessToken");

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCallImage(
            "post",
            "dealerInventory/inventory/upload-car-image",
            null,
            data,
            token
          );
          console.log();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  saveUploadCarImages(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/save-car-image",
            null,
            data,
            token
          );
          console.log();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  removeStockImage(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/remove-car-image",
            null,
            data,
            token
          );
          console.log();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  postCarDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSaveInventory(
            "post",
            "dealerInventory/inventory/save",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getCarList(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "post",
            "dealerInventory/inventory/search",
            null,
            data,
            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // getCarList(params) {
  //   const token = localStorage.getItem("accessToken");
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await call(
  //           "get",
  //           `admin/getVehicles?dealerId=${dealerId}`,
  //           params,
  //           null,
  //           token
  //         );
  //         resolve(res.data);
  //       } catch (err) {
  //         console.log(err);
  //         reject(err);
  //       }
  //     })();
  //   });
  // }

  getVehicleInfo(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall(
            "get",
            "dealer/getDealerVehicleInfo?car_id=" + data,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  imgTypeEnumList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "dealerInventory/inventory/get-image-tags",
            params,
            null,
            token
          );
          resolve(res.data?.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const carApi = new CarApi();
