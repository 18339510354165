import { getState, getCity } from ".";

class GeoRegion {
  getStateNames(dealer_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getState("get", "get-state");
        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getCityNames(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getCity("get", "get-city", { params });
        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
export const geographicRegion = new GeoRegion();
