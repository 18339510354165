export const INITIALIZE_THE_LOADER = "INITIALIZE_THE_LOADER";
export const STOP_THE_LOADER = "STOP_THE_LOADER";
export const INITIALIZE_THE_PAGE_LOADER = "INITIALIZE_THE_PAGE_LOADER";
export const STOP_THE_PAGE_LOADER = "STOP_THE_PAGE_LOADER";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const EDIT_BUYER_LEAD = "EDIT_BUYER_LEAD";
export const GET_BUYER_LEAD = "GET_BUYER_LEAD";
export const EDIT_SELLER_LEAD = "EDIT_SELLER_LEAD";
export const GET_SELLER_LEAD = "GET_SELLER_LEAD";
export const GET_CAR_LIST = "GET_CAR_LIST";
export const CREATE_CAR_DETAIL = "CREATE_CAR_DETAIL";
export const EDIT_CAR_DETAIL = "EDIT_CAR_DETAIL";
