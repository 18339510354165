import React, { Fragment, useEffect, useState } from "react";
import style from "./editCarDetails.module.scss";
import ImageCard from "../add-new-car/components/image-card/ImageCard";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import SelectableButton from "../../components/buttons/SelectableButton";
import InputField from "../../components/input-field/InputField";
import BackButton from "../../components/buttons/BackButton";
import { postData } from "../../components/services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { publicApi } from "../../api/publicApi";
import { editCarrDetails, getCarList } from "../../store/car/carAction";
import { carApi } from "../../api/carApi";
import MultiSelect from "../../components/buttons/multiSelect";
import DateField from "../../components/input-field/dateField";
import moment from "moment";
import { toast } from "react-toastify";
import SecondaryHeading from "../../components/primary-heading/SecondaryHeading";

export default function UpdateCarImages() {
  const [loaderTrue, setloaderTrue] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [alreadyImg, setalreadyImg] = useState([]);
  const [flags, setFlags] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageTypeEnum, setimageTypeEnum] = useState([]);
  const [stateData, setStateData] = useState(null);
  console.info("imageTypeEnum", imageTypeEnum, imagesData, stateData);

  const [dbImages, setDbImages] = useState([]);

  const [img, setImg] = useState();

  const brands = async () => {
    try {
      // const res = await publicApi.brandList();
      // setBrandList(res?.data);
      const res1 = await carApi.imgTypeEnumList();

      setimageTypeEnum(res1);
    } catch (err) {
      console.log(err);
    }
  };

  const [formData, setFormData] = useState({
    vehicleNo: stateData?.data?.vehicle_reg_no || "",
    images: stateData?.data?.images || null,
  });

  const fetchCarImages = () => {
    if (state) {
      console.log("Hi janmejay this is shantam issue");
      getCarList({
        dealerId: Number(localStorage.getItem("dealerId")),
        sku_id: [state],
      }).then((res) => {
        const filteredData = res?.payload?.data?.filter(
          (item) => item.carSkuId === state
        )?.[0];

        setStateData({
          data: filteredData,
        });
      });
    }
  };
  useEffect(() => {
    fetchCarImages();
  }, []);

  useEffect(() => {
    brands();
    if (stateData && stateData.data) {
      const initialFlags = [];
      setFlags(initialFlags);
    }
  }, [stateData, imagesData]);

  // useEffect(() => {
  //   if(dbImages.length){
  //     const result = {
  //       carSkuId: stateData?.data?.carSkuId,
  //       images: dbImages.map((image) => ({
  //         url: image.url,
  //         tagId: image.tagId
  //       }))
  //     };
  //     const response = carApi.saveUploadCarImages(result);
  //     if(response){
  //       toast.success("Image upload successfully");
  //       navigate("/car-management");
  //     } else {
  //       toast.error("Error uploading image");
  //     }
  //   }
  // }, [dbImages])

  useEffect(() => {
    if (dbImages.length) {
      const result = {
        carSkuId: stateData?.data?.carSkuId,
        images: dbImages.map((image) => ({
          url: image.url,
          tagId: image.tagId,
        })),
      };

      // Add a delay before making the API call
      const delayApiCall = setTimeout(async () => {
        try {
          const response = await carApi.saveUploadCarImages(result);
          if (response) {
            toast.success("Image uploaded successfully");
            navigate("/car-management");
          } else {
            toast.error("Error uploading image");
          }
        } catch (error) {
          toast.error("Error uploading image");
          console.error("Error:", error);
        }
      }, 2000); // 2-second delay

      // Cleanup the timeout if dbImages changes before the timeout completes
      return () => clearTimeout(delayApiCall);
    }
  }, [dbImages, navigate, stateData?.data?.carSkuId]);

  const images = {};
  const flagValue = [];
  flags.forEach((item) => {
    flagValue.push(item.value);
  });

  imagesData.forEach((item) => {
    images[item.apikey] = item.file;
  });

  const updateCarImage = async (e) => {
    console.log("called", imagesData);
    setLoading(true);
    setloaderTrue(true);
    e.preventDefault();
    let updateImagePath = [];

    // Check if imagesData exists and has at least one item
    if (imagesData && imagesData.length > 0) {
      console.log("called2");

      // Loop over each item in imagesData and make API call
      await Promise.all(
        imagesData.map(async (item) => {
          // Ensure valid file exists before creating FormData
          if (item?.file) {
            const formData = new FormData(); // Create new FormData for each file
            formData.append("file", item.file); // Append the file
            formData.append("tagId", item?.tagId); // Append additional data
            formData.append("carId", item?.carId); // Append additional data

            try {
              const response = await carApi.uploadCarImages(formData);
              if (response) {
                updateImagePath.push(response);
                setDbImages((prevImages) => [...prevImages, response]);
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }
        })
      );
    } else {
      console.log("No images to upload");
      navigate("/car-management");
    }

    setLoading(false);
    setloaderTrue(false);
  };

  const updateStateImage = (dbImageId) => {
    const updatedImagesDetails = stateData.data.imagesDetails.filter(
      (img) => img.imageId != dbImageId
    );

    setStateData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        imagesDetails: updatedImagesDetails,
      },
    }));

    // setImagesData([...imagesData, []]);
  };

  return (
    <div className={style["container"]}>
      {loaderTrue ? (
        <div class="loading">
          <div class="loader"></div>
        </div>
      ) : null}
      <div className={style["header-wrapper"]}>
        <BackButton
          onClick={() => {
            navigate("/car-management");
          }}
        />
        <PrimaryHeading
          title={"Upload Car Images"}
          fontSize={"1.75rem"}
          fontWeight={600}
        />
      </div>

      <div className={style["wrapper"]}>
        <div className={style["vehicle-images-container"]}>
          <PrimaryHeading
            title={`${stateData?.data?.make || ""} 
          ${stateData?.data?.modalName ? stateData?.data?.modalName : ""} 
          ${stateData?.data?.varientName ? stateData?.data?.varientName : ""} 
          ${
            stateData?.data?.carSkuId ? `|| ${stateData?.data?.carSkuId}` : ""
          }`}
            fontSize={"1.2rem"}
            fontWeight={500}
          />

          {/* <SecondaryHeading
            title={"Vehicle Details"}
            fontSize={"1.5rem"}
            fontWeight={500}
          /> */}

          <div className={style["images-wrapper"]}>
            {imageTypeEnum &&
              imageTypeEnum.length > 0 &&
              imageTypeEnum.map((eachImageType, index) => {
                const onIndex = [
                  { idx: 0, title: "Exterior" },
                  { idx: 8, title: "Interior" },
                  { idx: 15, title: "Others" },
                ];
                return (
                  <Fragment key={eachImageType.id}>
                    {onIndex.map((items) => (
                      <Fragment key={items.idx}>
                        {items.idx === index && (
                          <SecondaryHeading
                            title={items.title}
                            fontSize={"1.5rem"}
                            fontWeight={500}
                          />
                        )}
                      </Fragment>
                    ))}

                    <ImageCard
                      text={`${eachImageType.name}`}
                      apiKey={eachImageType.slug}
                      imagesData={imagesData}
                      setImagesData={setImagesData}
                      previewImage={alreadyImg}
                      imagePath={eachImageType?.image_path}
                      dbImagesData={
                        stateData?.data?.imagesDetails.find(
                          (item) => +item?.tagId === eachImageType?.id
                        )?.imgUrls
                      }
                      dbImageId={Number(
                        stateData?.data?.imagesDetails.find(
                          (item) => +item?.tagId === eachImageType?.id
                        )?.imageId
                      )}
                      tagIds={`${eachImageType.id}`}
                      carId={Number(stateData?.data.carId)}
                      carSkuId={stateData?.data?.carSkuId}
                      updateStateImage={updateStateImage}
                    />
                  </Fragment>
                );
              })}
          </div>

          <div className={style["submit-btn"]}>
            <PrimaryButton
              name={loading ? "Loading..." : "Save"}
              fontSize={"1rem"}
              containerStyle={style["primary-button-container-style"]}
              onClick={updateCarImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
