import { combineReducers } from "redux";
import auth from "./auth/authReducer";
import buyerLead from "./buyerLead/buyerLeadReducer";
import sellerLead from "./sellerLead/sellerLeadReducer";
import car from "./car/carReducer";
export default combineReducers({
  auth,
  buyerLead,
  sellerLead,
  car,
});
