  import { EDIT_SELLER_LEAD, GET_SELLER_LEAD } from "../types";

  const initialState = {
    sellerLeadList: [],
    total: 0,
    currentPage : "",
    totalCount : "",
    totalPageCount : "",
    hasNextPage : ""
  };

  export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
      case EDIT_SELLER_LEAD:
        const updateSellerLead = state.sellerLeadList.map((sellerLead) => {
          if (sellerLead.id === payload.lead_id) {
            return { ...sellerLead, ...payload };
          }
          return sellerLead;
        });
        return {
          ...state,
          sellerLeadList: updateSellerLead,
        };

      case GET_SELLER_LEAD:
        return {
          ...state,
          sellerLeadList: payload?.data,
          total: payload?.total,
          currentPage : payload?.currentPage, 
          totalCount : payload?.totalCount,
          totalPageCount : payload?.totalPageCount,
          hasNextPage : payload?.hasNextPage,

        };

      default:
        return state;
    }
  }
