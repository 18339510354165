import React, { useEffect, useRef, useState } from "react";
import style from "./imageCard.module.scss";
import { R } from "../../../../constants/resources";
import { API_URL } from "../../../../config";
import { carApi } from "../../../../api/carApi";

export default function ImageCard({
  text,
  imagesData,
  setImagesData,
  apiKey,
  previewImage,
  imagePath,
  dbImagesData,
  tagIds,
  carId,
  carSkuId,
  dbImageId,
  updateStateImage,
}) {
  const [imagePreview, setImagePreview] = useState(null);
  console.log("imagePreview", imagePreview, imagesData);
  const fileInputRef = useRef(null);
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (previewImage && previewImage.length > 0) {
      let finalImg = previewImage.find((x) => x.slug === apiKey);
      if (finalImg && finalImg["img_url"]) {
        setImagePreview(finalImg["img_url"]);
      }
    }
  }, [previewImage]);

  useEffect(() => {
    console.log(images?.length, "Updated images array:", images);
  }, [images]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    setImagesData([
      ...imagesData,
      { apikey: apiKey, file: file, tagId: e?.target?.name, carId: carId },
    ]);

    // const formData = new FormData();
    //       formData.append('file', file);
    //       formData.append('tagId', e?.target?.name);
    //       formData.append('carId', carId);
    //     try {
    //       const response = await carApi.uploadCarImages(formData);
    //       if(response){
    //         setImages((prevImages) => [...prevImages, response]);
    //       }
    //     } catch (error) {
    //       console.error('Error uploading image:', error);
    //     }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteClick = async (e) => {
    e.stopPropagation();
    setImagePreview(null);
    if (dbImageId) {
      const params = {
        carId,
        carSkuId,
        imageId: dbImageId,
      };
      try {
        const response = await carApi.removeStockImage(params);
        if (response?.status) {
          console.log("success");
          updateStateImage(dbImageId);
        } else {
          console.log("error");
        }
      } catch (error) {}
    } else {
      setImagePreview("");
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div htmlFor={`file-input-${text}`} className={style["image-container"]}>
      <input
        ref={fileInputRef}
        id={`file-input-${text}`}
        name={`${tagIds}`}
        type="file"
        accept="image/*"
        className={style["input-tag"]}
        onChange={handleImageChange}
      />
      {/* {!imagePreview ? ( */}
      <div className={style["preview-wrapper"]} onClick={handleClick}>
        <div style={{ display: "flex" }}>
          <img
            src={
              imagePreview
                ? imagePreview
                : dbImagesData
                ? dbImagesData
                : imagePath
            }
            className={style["preview-image"]}
            alt={"preview"}
          />
          {/* <span>{text}</span> */}
        </div>
        {(imagePreview || dbImagesData) && (
          <img
            title="Delete"
            src={R.ic_delete}
            className={style["delete-icon"]}
            alt={"delete"}
            onClick={handleDeleteClick}
          />
        )}
      </div>
      {/* ) : ( */}
      <div className={style["wrapper"]} onClick={handleClick}>
        {/* <img
          src={R.ic_add}
          className={style["add-icon"]}
          alt={"add-icon"}
          onClick={handleClick}
        /> */}
        <p className={style["text"]}>{text}</p>
      </div>
      {/* )} */}
    </div>
  );
}
